@import "global";
.staff-card {
  $this: &;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  @include aspectRatio(1);

  &:hover {
    #{$this}__content {
      opacity: 1;
    }
  }

  &__block {
    @include position(absolute,0);
    background-color: $cPrimaryLight;
    transform-origin: right center;
  }

  &__content {
    @include visual-angle('top right',100px, 100px, $cWhite, false);
    @include position(absolute,0);
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    background-color: $cPrimary;
    opacity: 0;
    color: $cWhite;
    transition: opacity .4s;
  }
  &__title {
    line-height: 1.25;
    margin-bottom: 5px;
  }
  &__image {
    z-index: -1;
    @include visual-angle('top right',100px, 100px, $cWhite, false);
    @include position(absolute,0);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__subtitle {
    margin-top: 0;
  }
  &__social {
    padding-top: 20px;
    margin: auto 0 0 0;
    a {
      transition: opacity 0.4s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  @include breakpoint(tablet-portrait) {
    min-height: 415px;
  }
  @include breakpoint(tablet-portrait down) {
    flex-direction: column;
    &::before {
      display: none;
    }
    &__content {
      opacity: 1;
      position: static;
      color: $cBlack;
      background-color: transparent;
      @include padding(20px 0 35px);
      path {
        fill: $cBlack;
      }
    }
    &__image {
      position: static;
      width: 100%;
    }
  }
  @include breakpoint(phone down) {
    &__content {
      padding-bottom: 10px;
      &:before {
        @include size(80px);
      }
    }
    &__image {
      &:before {
        @include size(80px);
      }
    }
  }
}
